<template>
  <Dialog
    v-model:visible="modalEditarLotes"
    :breakpoints="{'1300px': '100vw'}" :style="{width: '80vw'}"
    :modal="true"
    :closeOnEscape="true"
    :dismissableMask="true"
  >
    <template #header>
      <div style="color:#1c3faa;">
        <h4>
          <i class="pi pi-pencil" style="font-size: 1rem;margin-right:10px"></i>
          <strong style="font-size:20px">Editar Asignación Lotes</strong>
        </h4>
      </div>
    </template>
    <div class="grid lg:grid-cols-10 xl:grid-cols-10 grid-cols-1 gap-2">
      <div class="col-span-6" v-if="!dataMx.medAdicional">
        <span class="font-bold mr-2 text-xs">Cod. Medicamento: </span><br>
        <p class="text-xs">{{ dataMx.U_PHR_ItemCodSolicitado + ' - ' + dataMx.ItemNameOriginal }}</p>
      </div>
      <div class="col-span-6"  v-if="dataMx.medAdicional">
        <span class="font-bold mr-2 text-xs">Cod. Medicamento: </span><br>
        <p class="text-xs">{{ dataMx.nCompleto }}</p>
      </div>
      <div class="flex lg:flex-col">
        <span class="font-bold text-xs mr-2">Conversión Original: </span>
        <p class="text-xs">{{ $h.formatNumber(dataMx.U_PHR_Conversion) }}</p>
      </div>
      <div class="flex lg:flex-col">
        <span class="font-bold mr-2 text-xs">Cant. Factura: </span>
        <p class="text-xs">{{ $h.formatNumber(onQuantity) }}</p>
      </div>
      <div class="flex lg:flex-col">
        <span class="font-bold mr-2 text-xs">Cant. Recibido: </span>
        <p class="text-xs">{{ $h.formatNumber(dataMx.receivedQuantity) }}</p>
      </div>
      <div class="flex lg:flex-col">
        <span class="font-bold mr-2 text-xs">Cant. Defectuosa: </span>
        <p class="text-xs">{{ $h.formatNumber(onQuantityDefecto) }}</p>
      </div>
    </div>
    <div class="grid lg:grid-cols-10 xl:grid-cols-10 grid-cols-1 gap-2" v-show="dataMx.ItemCode !== dataMx.U_PHR_ItemCodSolicitado">
      <div class="col-span-6">
        <span class="font-bold text-xs">Cod. Medicamento Sustituto: </span><br>
        <p class="text-xs">{{ dataMx.ItemCode + ' - ' + dataMx.U_PHR_ItemSustituto }}</p>
      </div>
      <div class="flex lg:flex-col">
        <span class="font-bold text-xs mr-2">Conversión sustituto: </span>
        <p class="text-xs">{{ dataMx.U_PHR_ConversionSustituto }}</p>
      </div>
    </div>
    <div class="gap-2 grid 2xl:grid-cols-4 xl:grid-cols-2 lg:grid-cols-2 sm:grid-cols-2 xs:grid-cols-1 pt-3">
      <div>
        <span class="font-bold mr-2 text-xs">Ubicaciones: </span><br>
        <Dropdown
          v-model="loteAgregado.AbsEntry"
          :options="arrayUbicaciones"
          class="rounded-md border-gray-300 text-xs w-full"
          optionLabel="SL1Code"
          :showClear="true"
          :disabled="$can('pharmasan.compras.logistica.recepcion-pedidos.listado-recepciones.area-compras') && !$can('pharmasan.compras.logistica.recepcion-pedidos.listado-recepciones.cambiar-ubicacion-compras')"
          optionValue="AbsEntry"
          placeholder="Seleccione una ubicación..."
        />
        <br>
        <MessageError class="text-xs" :text="errors.AbsEntry"/>
      </div>
      <div>
        <span class="font-bold text-xs w-full">Lote: </span><br>
        <InputText @blur="onSearchBatchNumber(dataMx.ItemCode)" class="p-inputtext-xs w-full"
                   v-model="loteAgregado.lote"/>
        <br>
        <MessageError class="text-xs" :text="errors.lote"/>
      </div>
      <div>
        <span class="font-bold mr-2 text-xs w-full">Cantidad: </span><br>
        <InputNumber inputId="integeronly" class="p-inputtext-xs w-full" v-model="loteAgregado.cantidad"/>
        <br>
        <MessageError class="text-xs" :text="errors.cantidad"/>
      </div>
      <div>
        <span class="font-bold mr-2 text-xs w-full">Fecha Venc.: </span><br>
        <input :disabled="disableExpDate"
               @change="futureExpDate(loteAgregado.expDate)"
               style="padding: 0.30rem;" :min="minDate"
               :max="maxDate"
               class="p-button-secondary border rounded-md p-inputtext-xs w-full"
               v-model="loteAgregado.expDate"
               type="date"><br>
        <MessageError class="text-xs" :text="errors.expDate"/>
      </div>
    </div>
    <div class="pt-8">
      <DataTable
        :value="dataLotes"
        class="p-datatable-sm text-xs"
        showGridlines
        dataKey="id"
        responsiveLayout="scroll"
      >
        <template #empty>
          Agregue un lote para asignar.
        </template>
        <template #header>
          <div class="flex justify-end align-items-center">
                  <span class="p-input-icon-left">
                    <Button icon="pi pi-plus" :disabled="validateCant" label="Agregar Lote" @click="addLine"
                            class="p-button-outlined p-button-success p-button-xs"/>
                  </span>
          </div>
        </template>
        <Column field="" header="Ubicación" headerClass="lg:text-xs" class="my-1 py-1 shadow-xs">
          <template #body="{data}">
            <Dropdown
              v-model="data.AbsEntry"
              :options="arrayUbicaciones"
              class="rounded-xs border-gray-300 w-full text-xs"
              optionLabel="SL1Code"
              optionValue="AbsEntry"
              disabled
              placeholder="Seleccione una ubicacion..."
            />
          </template>
        </Column>
        <Column field="" header="Lote" headerClass="lg:text-xs" bodyStyle="text-align: center"
                class="my-1 py-1 shadow-xs">
          <template #body="{data}">
            <p class="text-xs">{{ data.DistNumber }}</p>
          </template>
        </Column>
        <Column field="" header="Cantidad" bodyStyle="text-align: center" headerClass="lg:text-xs"
                class="my-1 py-1 shadow-xs">
          <template #body="{data}">
            <p class="text-xs">{{ $h.formatNumber(data.CantidadLote) }}</p>
          </template>
        </Column>
        <Column field="" header="Fecha Vencimiento" bodyStyle="text-align: center" headerClass="lg:text-xs"
                class="my-1 py-1 shadow-xs">
          <template #body="{data}">
            <p class="text-xs">{{ data.ExpDate }}</p>
          </template>
        </Column>
        <Column field="" header="Acciones" bodyStyle="text-align: center" headerClass="lg:text-xs"
                class="my-1 py-1 shadow-xs">
          <template #body="slotProps">
            <div class="flex gap-2 justify-center">
              <Button v-tooltip.top="'Editar'" @click="editBatchNumber(slotProps)"
                      class="p-button-text p-button-help  p-0">
                <editIcon class="w-4"/>
              </Button>
              <Button v-if="slotProps.data.alertaExpDate"
                      v-tooltip.top="'Cargar carta'"
                      @click="uploadCarta(slotProps)"
                      class="p-button-text p-button-info p-0">
                <corner-right-upIcon class="w-4"/>
              </Button>
              <Button v-tooltip.top="'Ver'" v-show="slotProps.data.url"
                      @click="viewCarta(slotProps.data.id)"
                      class="p-button-text p-button-danger p-0">
                <eyeIcon class="w-4"/>
              </Button>
              <Button v-if="slotProps.data.cartaPdf || slotProps.data.url" v-tooltip.top="'Eliminar carta'"
                      @click="removeCarta(slotProps)"
                      class="p-button-text p-button-secondary p-0">
                <folder-minusIcon class="w-4"/>
              </Button>
              <Button v-tooltip.top="'Novedades'" @click="openModalNovedades(slotProps)"
                      class="p-button-text p-button-warning p-0">
                <alertTriangleIcon class="w-4"/>
              </Button>
              <Button v-tooltip.top="'Eliminar'" @click="deleteLines(slotProps)"
                      class="p-button-text p-button-danger p-0">
                <x-squareIcon class="w-4"/>
              </Button>
              <input type="file" ref="pdfCartaInput" class="hidden" accept="application/pdf" @change="handleChangePdf"/>
            </div>
            <small class="text-muted mt-2">{{ slotProps.data.NombreCartaPdf }}</small>
          </template>
        </Column>
      </DataTable>
    </div>
    <div class="flex justify-center mt-2">
      <Button icon="pi pi-check-square" label="Confirmar" @click="confirmLine" class="p-button-outlined p-button-xs"/>
    </div>
    <modalNovedades ref="modalNovedades" :isEdit="true" @updateNovedades="updateNovedades"/>
  </Dialog>
</template>

<script>
import { computed, defineAsyncComponent, ref } from 'vue'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import { useField, useForm } from 'vee-validate'
import dayjs from 'dayjs'
import RecepcionPedidosService from '@/apps/pharmasan/compras/logistica/services/rcp-pedidos.service'
import { useStore } from 'vuex'
import { helper } from '@/utils/helper'
export default {
  name: 'modalEditarLotes',
  components: {
    modalNovedades: defineAsyncComponent(() =>
      import('./../components/modalRegistrarNovedades.vue')
    )
  },
  emits: ['refrescarLotes'],
  setup (props, { emit }) {
    const store = useStore()
    const modalNovedades = ref()
    const modalEditarLotes = ref(false)
    const dataMx = ref({})
    const minDate = ref('')
    const maxDate = ref('')
    const arrayUbicaciones = ref([])
    const lineClose = ref(false)
    const disableExpDate = ref(false)
    const valueDataTemp = ref({})
    const pdfCartaInput = ref()
    const permisoCartaCompromiso = ref(false)
    const schema = yup.object({
      AbsEntry: yup.string().required('El campo es requerido').label('Código de bodega'),
      lote: yup.string('').required('El campo es requerido').label('Lote medicamento'),
      cantidad: yup.number().required('El campo es requerido').label('Cantidad de lote'),
      expDate: yup.date().required('El campo es requerido').label('Fecha vencimiento medicamento'),
      cartaBase64: yup.string('').label('Variable temporal carta compromiso'),
      cartaNombre: yup.string('').label('Variable temporal carta compromiso'),
      novedades: yup.array()
    })
    useField('lote')
    useField('cantidad')
    useField('expDate')
    useField('cartaBase64')
    useField('cartaNombre')
    const _RecepcionPedidosService = ref(new RecepcionPedidosService())
    const { errors, values: loteAgregado, handleSubmit, handleReset } = useForm({
      validationSchema: schema
    })
    const onQuantity = computed(() => {
      const resultCantidad = dataMx.value.lotes.filter((x) => x.visible).reduce((total, current) => {
        return total + current.CantidadLote
      }, 0)
      return dataMx.value.Quantity - resultCantidad
    })
    const validateCant = computed(() => {
      if (onQuantity.value === 0) {
        return true
      } else {
        return false
      }
    })
    const onQuantityDefecto = computed(() => {
      let resultCantidad = 0
      // 877 absEntry de la ubicacion de devoluciones pedidos
      resultCantidad = dataMx.value.lotes.filter(a => a.AbsEntry === 877).reduce((total, current) => {
        return total + current.CantidadLote
      }, 0)
      return resultCantidad < 0 ? 0 : resultCantidad - dataMx.value.cantidadDefectuosa
    })
    const dataLotes = computed(() => {
      return dataMx.value.lotes.filter((x) => x.visible)
    })
    const addLine = handleSubmit((values) => {
      if (!values) return
      dataMx.value.lotes.forEach((element, index) => {
        if (!element.visible) dataMx.value.lotes.splice(index, 1)
      })
      if (values.lote.length >= 36) {
        Swal.fire({
          icon: 'info',
          text: 'La cantidad de caracteres del lote es demasiado larga, por favor verificar.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        })
        return
      }
      if (loteAgregado.expDate >= minDate.value) {
        if (onQuantity.value >= loteAgregado.cantidad && loteAgregado.cantidad > 0) {
          const infoExtraLote = arrayUbicaciones.value.find(a => a.AbsEntry === values.AbsEntry)
          dataMx.value.lotes.push({
            id: loteAgregado.id ?? null,
            DistNumber: loteAgregado.lote.toUpperCase(),
            CantidadLote: loteAgregado.cantidad,
            ExpDate: loteAgregado.expDate,
            AbsEntry: loteAgregado.AbsEntry,
            url: loteAgregado.url,
            cartaPdf: loteAgregado.cartaBase64 ?? '',
            NombreCartaPdf: loteAgregado.cartaNombre ?? '',
            visible: true,
            alertaExpDate: futureExpDate(loteAgregado.expDate),
            novedades: loteAgregado.novedades && loteAgregado.novedades.length ? loteAgregado.novedades.map((a) => {
              /* actualiza información de lote en las novedades */
              a.AbsEntry = loteAgregado.AbsEntry
              a.DistNumber = loteAgregado.lote.toUpperCase()
              a.ExpDate = loteAgregado.expDate
              a.Quantity = parseInt(loteAgregado.cantidad)
              a.SL1Abs = infoExtraLote.SL1Abs
              return a
            }) : []
          })
          loteAgregado.id = null
          loteAgregado.cantidad = null
          loteAgregado.lote = null
          loteAgregado.expDate = null
          loteAgregado.novedades = []
        } else {
          Swal.fire({
            icon: 'info',
            text: 'La cantidad debe ser mayor que 0 y no puede ser superior a la cantidad restante del pedido.',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true
          })
        }
      } else {
        Swal.fire({
          icon: 'info',
          text: 'La fecha de vencimiento para este medicamento ya expiró.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        })
      }
    })

    const openModalNovedades = ({ data }) => {
      const payload = {
        lote: {
          DistNumber: data.DistNumber,
          Quantity: data.CantidadLote,
          ExpDate: data.ExpDate,
          AbsEntry: data.AbsEntry,
          SL1Abs: data.SL1Abs,
          novedades: data.novedades.map((n) => {
            return {
              ItemCode: dataMx.value.ItemCode,
              U_PHR_ItemCodSolicitado: dataMx.value.U_PHR_ItemCodSolicitado,
              U_PHR_ItemSustituto: dataMx.value.U_PHR_ItemSustituto ?? dataMx.value.U_PHR_ItemSustituto,
              Dscription: dataMx.value.ItemName,
              LineNum: dataMx.value.LineNum,
              DocNum: dataMx.value.BaseRef ? dataMx.value.BaseRef : null,
              DocEntry: dataMx.value.BaseEntry ? dataMx.value.BaseEntry : null,
              DistNumber: data.DistNumber,
              Quantity: data.CantidadLote,
              ExpDate: data.ExpDate,
              AbsEntry: data.AbsEntry,
              SL1Abs: data.SL1Abs,
              ...n
            }
          })
        },
        medicamento: {
          labelLote: `Lote: ${data.DistNumber} Cant.: ${data.CantidadLote} Fec. Venc.: ${data.ExpDate} Ubicación: ${data.SL1Abs === 39 ? 'DEVOLUCIONES' : 'PENDIENTESOV'}`,
          LineNum: dataMx.value.LineNum,
          DocNum: dataMx.value.BaseRef ? dataMx.value.BaseRef : null,
          DocEntry: dataMx.value.BaseEntry ? dataMx.value.BaseEntry : null,
          ItemCode: dataMx.value.ItemCode,
          Dscription: dataMx.value.ItemName,
          nCompleto: dataMx.value.nCompleto,
          Quantity: dataMx.value.Quantity,
          receivedQuantity: dataMx.value.receivedQuantity,
          cantidadDefectuosa: dataMx.value.cantidadDefectuosa,
          U_PHR_ItemCodSolicitado: dataMx.value.U_PHR_ItemCodSolicitado,
          U_PHR_ItemSustituto: dataMx.value.U_PHR_ItemSustituto ?? dataMx.value.U_PHR_ItemSustituto,
          Price: dataMx.value.UnitPrice,
          CodeBars: dataMx.value.CodeBars
        }
      }
      modalNovedades.value.openModal(payload)
    }
    const updateNovedades = ({ nove, infoLote }) => {
      /*
      * DistNumber pos 0
      * Quantity pos 1
      * ExpDate pos 2
      * AbsEntry pos 3
      * SL1Abs pos 4
      */
      const indexNov = dataMx.value.lotes.findIndex(l => `${l.DistNumber}${l.CantidadLote}${l.ExpDate}${l.AbsEntry}` === `${infoLote[0]}${infoLote[1]}${infoLote[2]}${infoLote[3]}`)
      dataMx.value.lotes[indexNov].novedades = nove
    }
    const uploadCarta = async ({ data }) => {
      valueDataTemp.value = data
      pdfCartaInput.value.click()
    }
    const handleChangePdf = async () => {
      if (pdfCartaInput.value.files[0]) {
        const findIndex = dataMx.value.lotes.findIndex((x) => x.id === valueDataTemp.value.id)
        dataMx.value.lotes[findIndex].cartaPdf = await helper.base64String(pdfCartaInput.value.files[0])
        dataMx.value.lotes[findIndex].NombreCartaPdf = pdfCartaInput.value.files[0].name
        dataMx.value.lotes[findIndex].alertaExpDate = false
      }
    }
    const removeCarta = ({ data }) => {
      Swal.fire({
        icon: 'info',
        title: '¿Esta seguro/a?',
        html: `Se eliminará la carta de compromiso del lote: <strong>${data.DistNumber}</strong><br>Con fecha de vencimiento: <strong>${data.ExpDate}</strong><br>¿Desea continuar?.`,
        showConfirmButton: true,
        focusCancel: true,
        showCancelButton: true
      }).then((response) => {
        if (response.isConfirmed) {
          _RecepcionPedidosService.value.deleteCarta(data.id).then(({ data }) => {
            if (data.id) {
              Swal.fire({
                icon: 'success',
                title: 'Éxito',
                html: `La carta de compromiso del lote: <strong>${data.DistNumber}</strong><br>Con fecha de vencimiento: <strong>${data.ExpDate}</strong><br>se eliminó correctamente.`,
                showConfirmButton: true
              }).then((resp) => {
                if (resp.isConfirmed) {
                  emit('refrescarLotes', dataMx.value)
                }
              })
            }
          })
        }
      })
    }
    const editBatchNumber = ({ data, index }) => {
      const findIndex = dataMx.value.lotes.findIndex((a) => a.id === data.id && a.visible)
      loteAgregado.id = dataMx.value.lotes[findIndex].id
      loteAgregado.cantidad = dataMx.value.lotes[findIndex].CantidadLote
      loteAgregado.lote = dataMx.value.lotes[findIndex].DistNumber
      loteAgregado.expDate = dataMx.value.lotes[findIndex].ExpDate
      loteAgregado.AbsEntry = dataMx.value.lotes[findIndex].AbsEntry
      loteAgregado.url = dataMx.value.lotes[findIndex].url
      loteAgregado.cartaBase64 = dataMx.value.lotes[findIndex].cartaBase64
      loteAgregado.cartaNombre = dataMx.value.lotes[findIndex].cartaNombre
      loteAgregado.novedades = dataMx.value.lotes[findIndex].novedades
      dataMx.value.lotes[findIndex].visible = false
    }
    const deleteLines = ({ index, data }) => {
      Swal.fire({
        icon: 'question',
        title: 'Esta seguro/a?',
        html:
          `¿Desea eliminar el lote: <strong>${data.DistNumber}</strong><br>Fecha de vencimiento: <strong>${data.ExpDate}</strong><br>Cantidad:<strong>${data.CantidadLote}</strong> ?`,
        showConfirmButton: true,
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: 'Si, eliminar',
        cancelButtonText: 'No, cancelar'
      }).then(async ({ value }) => {
        if (value) {
          let messageInfo = ''
          if (data.id) {
            const idsNovedades = [...new Set(data.novedades.map(a => a.novId))]
            await _RecepcionPedidosService.value.deleteLote(data.id, idsNovedades).then((response) => {
              if (response.data.message) {
                messageInfo = data.message
              }
            })
          }
          if (messageInfo === '') {
            Swal.fire({
              icon: 'success',
              title: 'Éxito',
              text: 'Se eliminó el lote correctamente',
              showConfirmButton: true
            })
            dataMx.value.lotes.splice(index, 1)
          } else {
            Swal.fire({
              icon: 'warning',
              title: 'Advertencia',
              text: messageInfo,
              showConfirmButton: true
            })
          }
        }
      })
    }
    const onSearchBatchNumber = (ItemCode) => {
      if (loteAgregado.lote) {
        _RecepcionPedidosService.value.getBatchNumber(loteAgregado.lote, ItemCode).then(({ data }) => {
          if (data.length > 0) {
            futureExpDate(data[0].ExpDate)
            loteAgregado.expDate = data[0].ExpDate
            disableExpDate.value = true
          } else {
            disableExpDate.value = false
          }
        })
      }
    }
    const confirmLine = () => {
      if (onQuantity.value === 0) {
        let validate = false
        dataMx.value.lotes.forEach((element) => {
          if (element.alertaExpDate && permisoCartaCompromiso.value) {
            validate = true
            Swal.fire({
              icon: 'info',
              text: 'La carta de compromiso para el medicamento próximo a vencer es obligatoria, por favor revisar.',
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3500,
              timerProgressBar: true
            })
          }
        })
        if (!validate) {
          modalEditarLotes.value = false
        }
      } else {
        Swal.fire({
          icon: 'info',
          text: 'Faltan cantidades por asignar, por favor validar.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        })
      }
    }
    const futureExpDate = (ExpDate) => {
      const date = new Date()
      const validateDays = date.setMonth(date.getMonth() + 12) // fecha actual + 12 meses, para validar que la fecha de vencimiento este en el rango
      if (ExpDate >= minDate.value && dayjs(validateDays).format('YYYY-MM-DD') >= ExpDate) {
        Swal.fire({
          icon: 'info',
          text: 'La fecha de vencimiento de este medicamento esta próxima a expirar.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        })
        return true
      }
      return false
    }
    const onValidatePermiso = (permiso) => {
      const user = store.getters['auth/user']
      if (!user.admin) {
        const permissions = store.getters['auth/getPermissions']
        if (permissions.some(a => a === permiso)) return true
        return false
      }
      return true
    }
    const viewCarta = (id) => {
      _RecepcionPedidosService.value.verPdf(id)
    }
    const openModalEditarLotes = ({ detalle, indice, ubicaciones, permisoCarta }) => {
      const find = detalle.sustitutos.find((x) => x.ItemCode === detalle.ItemCode)
      if (find) {
        detalle.U_PHR_ItemSustituto = find.Dscription
        detalle.U_PHR_ConversionSustituto = find.U_PHR_Conversion
      }
      arrayUbicaciones.value = ubicaciones
      handleReset()
      const date = new Date()
      minDate.value = dayjs(date).format('YYYY-MM-DD')
      const futureDate = new Date()
      futureDate.setFullYear(date.getFullYear() + 5)
      if (onValidatePermiso('pharmasan.compras.logistica.recepcion-pedidos.listado-recepciones.fecha-vencimiento-mayor')) {
        maxDate.value = dayjs(futureDate).format('YYYY-MM-DD')
      }
      dataMx.value = detalle
      if (dataMx.value.lotes.length) {
        dataMx.value.lotes.forEach((element) => {
          element.visible = true
        })
      }
      lineClose.value = indice
      permisoCartaCompromiso.value = permisoCarta
      loteAgregado.AbsEntry = ubicaciones[0].AbsEntry
      modalEditarLotes.value = true
    }
    return {
      modalEditarLotes,
      openModalEditarLotes,
      dataMx,
      addLine,
      deleteLines,
      loteAgregado,
      onQuantity,
      confirmLine,
      validateCant,
      errors,
      minDate,
      maxDate,
      onSearchBatchNumber,
      disableExpDate,
      futureExpDate,
      editBatchNumber,
      dataLotes,
      onValidatePermiso,
      arrayUbicaciones,
      onQuantityDefecto,
      viewCarta,
      removeCarta,
      pdfCartaInput,
      handleChangePdf,
      uploadCarta,
      permisoCartaCompromiso,
      modalNovedades,
      openModalNovedades,
      updateNovedades
    }
  }
}
</script>

<style scoped>
::v-deep(.pi) {
  font-size: 0.8rem;
}

::v-deep(.p-column-header-content) {
  justify-content: center;
}
</style>
